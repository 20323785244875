import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import find from 'lodash/find';
import { useIntl } from 'react-intl';
import isFunction from 'lodash/isFunction';
import { Button, CopyButton, Dialog } from 'zent';
import cn from 'classnames';
import { OPEN_MODE, MessageType } from 'const';
import { enterpage } from '../../../util/log-buried-point/ambush-log';
import { sendTimlingLog } from '../../../util/log-buried-point/ambush-log/performance-timing';
import ShopApi from '../../../api/shop';
import { Message } from '../../../util/postmessage';
import { SectionsMap, globalConfigOfSectionMap } from '../config/sections_map';
import { intlMessage as $t } from '../../../util/intl';
import { initGlobalStyle } from '../../../util/set-style';
import Style from './style';
import { getQueryString } from '../../../util';
import { triggerFbMessage } from '../../../util/sdk/facebook-message';
import { memoedWrapedSectionsMap } from '../../components/decoration-wrapper';
import { DistributionCoupon } from '../../components/distribution-coupon';
import { ScrollTop } from '../../components/scroll-top';
import { useDecorationActiveMap } from '../../../hook/decoration-active-map';
import useGlobalAnimation from '../../../hook/use-global-animation';
import useKeyboardBind from '../../../hook/use-keyboard-bind';
import emitter, { THEME_DATE_CHANGE } from '../../../event';
import lodashMerge from 'lodash/merge';
import { blockJump } from 'helper/theme';
import debounce from 'lodash/debounce';

const { openDialog, closeDialog } = Dialog;
const PreviewCloseDialog = 'PreviewCloseDialog';

const PreviewMode = {
  Simple: 1,
  Normal: 2,
};

const PageNotNeedHeaderOrFooter = ['/order/subscribe/', '/close'];
function getBoundingClientRect(element) {
  const rect = element.getBoundingClientRect();
  return {
    top: rect.top,
    right: rect.right,
    bottom: rect.bottom,
    left: rect.left,
    width: rect.width,
    height: rect.height,
    x: rect.x,
    y: rect.y,
  };
}

const judgeIsNotNeedHeader = (path) => {
  return path !== '/' && find(PageNotNeedHeaderOrFooter, (item) => item.indexOf(path) !== -1);
};

const defaultFun = ({ data, setThemeData }) => {
  initGlobalStyle(data);
  setThemeData(data);
  emitter.emit(THEME_DATE_CHANGE, data);
};
const debounceFun = debounce(defaultFun, 200);

const Home = (props) => {
  // 这部分是动态表单的逻辑，之后要移动到动态表单里去的，先借用下首页
  const intl = useIntl();

  const { mode, tid, system = false, custom: isCustom } = getQueryString(props.location.search);
  const isPrev = (mode === OPEN_MODE.PREVIEW_SIMPLE || mode === OPEN_MODE.PREVIEW_NORMAL) && !system;
  const { initialData = {}, history, children, pageType } = props;
  const { kdtId } = initialData;
  const domain = get(initialData, 'shopInfo.primaryDomain', '');
  const [showBottom, setShowBottom] = useState(isPrev);
  if (isCustom) {
    initialData.themeData.current.content_for_index = [];
  }
  // console.log('home initialData', initialData);
  // console.log(initialData.themeData, '=====initialData.themeData=====');
  const [theme, setThemeData] = useState(initialData.themeData || {});
  const themeOrigin = JSON.parse(JSON.stringify(initialData.themeData));
  const [templateName, setTemplateName] = useState('');
  const [current, setCurrent] = useState('');

  const [sectionOrderInfo, setSectionOrderInfo] = useState(null);
  const customerId = get(props, 'initialData.userInfo.customerId');
  const currency = get(props, 'initialData.shopSetting.currency');

  const isSimple = Number(mode) === PreviewMode.Simple;
  const isNotNeedHeader = judgeIsNotNeedHeader(props.location?.pathname);
  const isEdit = Number(mode) === OPEN_MODE.EDIT_NORMAL;

  const [emptyKey, setEmptyKey] = useState(Math.random());
  // console.log('isEdit mode', isEdit, mode);
  useKeyboardBind(isEdit, current);

  const onPreviewClose = () => {
    window.close();
  };

  const onBottomHide = () => {
    setShowBottom(false);
  };

  const onEditClick = () => {
    const link = `https://${domain}/admin/apps/store#/design/edit/${tid}`;

    window.open(link);
  };

  const onPreviewShare = () => {
    openDialog({
      dialogId: PreviewCloseDialog,
      title: $t(intl, 'design.shared_preview'),
      children: (
        <Style>
          <div className='preview-share-box'>
            <div className='preview-share-desc'>
              <p>{$t(intl, 'design.shared_preview_to_get', { name: templateName })}</p>

              <p>{$t(intl, 'design.link_preview_14_no_buy')}</p>
            </div>
            <div className='preview-link-content'>
              <div className='preview-link-text'>{window.location.href}</div>
              <CopyButton text={window.location.href}>
                <Button type='primary'>{$t(intl, 'design.copy_link')}</Button>
              </CopyButton>
            </div>
          </div>
          <div className='preview-footer-btn'>
            <Button onClick={() => closeDialog(PreviewCloseDialog)}>{$t(intl, 'general.close')}</Button>
          </div>
        </Style>
      ),
    });
  };

  const getTemplateInitData = async () => {
    const getPreviewTemplate = (templateId) => {
      return ShopApi.getSystemTemplateById({
        kdtId,
        templateId,
      });
    };

    const template = tid && (await getPreviewTemplate(tid));

    return {
      template,
    };
  };

  const handleNewMessage = (data, type) => {
    window.isEdit = true; // 取巧了  小兄弟 但是没有全局的东西 js跳转不好控制 就先这样取巧吧
    if (!data && type !== MessageType.CURRENT_SECTION) return;
    switch (type) {
      case MessageType.CURRENT_SECTION:
        setCurrent(data);
        break;

      case 'StorefrontMessage::SetSectionOrder':
        setThemeData(data.themeData);
        setSectionOrderInfo(data.payload);

        break;
      case MessageType.EDIT_INFO:
        // console.log('data.payload,EDIT_INFO', data);
        window.EDIT_INFO = JSON.parse(data);
        break;
      case 'decoration_b_lang':
        window.decoration_b_lang = JSON.parse(data);
        setEmptyKey(Math.random());
        break;
      case MessageType.SITE_SWITCH: {
        if (theme?.current?.sections?.['site-select']) {
          delete theme.current.sections['site-select'];
        }
        const newThemeData = lodashMerge({}, theme, data);
        setThemeData(newThemeData);
        setTimeout(() => {
          blockJump();
        }, 0);
        break;
      }
      case MessageType.ADMIN_CHANGE:
      default:
        debounceFun({ data, setThemeData });
        break;
    }
  };

  useEffect(() => {
    if (sectionOrderInfo) {
      // 这里存在数据滞后性问题 暂时采用这样的hack解决
      setTimeout(() => {
        const target = document.getElementsByClassName(`section-${sectionOrderInfo.selectedSectionGid}`)[0];
        if (target) {
          target.scrollIntoView({ block: 'start' });
          window.scrollBy(0, -280);
          // 这里需要延时不然获取的位置不准确 需要等滚动完毕 不然getBoundingClientRect获取的数据不准确
          setTimeout(() => {
            window.parent.postMessage(
              {
                type: 'StorefrontMessage::SetSectionOrder',
                payload: getBoundingClientRect(target),
              },
              '*',
            );
          }, 200);
        }
      }, 0);
    }
  }, [sectionOrderInfo]);

  const scrollSectionInView = (sectionEl) => {
    // const sectionEl = document.querySelector(`.section-${current}`);
    if (sectionEl) {
      if (current.indexOf('product-show-chocolate') === 0 || current.indexOf('slideshow-video-pic') === 0) {
        sectionEl.scrollIntoView({ behavior: 'smooth', block: 'start' });
      } else {
        sectionEl.scrollIntoView({ behavior: 'smooth', block: 'center' });
        // 我醉了 为啥后面不滚了呢 intro的兼容
        if (current.indexOf('featured-content') > -1) {
          setTimeout(() => {
            sectionEl.scrollIntoView({ behavior: 'smooth', block: 'center' });
            const targetEle = document.querySelector('.wrapper-section-operate.active');
            setTimeout(() => {
              window.parent.postMessage(
                {
                  type: 'StorefrontMessage::IntroPos',
                  payload: getBoundingClientRect(targetEle),
                },
                '*',
              );
            }, 1000);
          }, 1000);
        } else {
          window.parent.postMessage(
            {
              type: 'StorefrontMessage::IntroPos',
              payload: {},
            },
            '*',
          );
        }
      }
    }
  };

  const getSocialShareData = () => {
    return theme.current?.sections?.['social-share']?.settings ?? {};
  };

  useEffect(() => {
    if (window.self !== window.parent) {
      const message = new Message(props, handleNewMessage);

      // /** 主动取数据 */
      return () => {
        message.removeListenMessage();
      };
    } else {
      getTemplateInitData().then(({ template }) => {
        // console.log('template', template, theme);
        setThemeData(theme);
        initGlobalStyle(theme);
        setTemplateName(template?.templateName);
      });
    }
  }, [initialData.themeData]);

  useEffect(() => {
    enterpage({
      params: {
        path: window.location.pathname,
        pageType,
      },
      initialData,
    });
    window.onload = function () {
      sendTimlingLog({ initialData });
    };
  }, [pageType]);

  useEffect(() => {
    // 加载并展示facebook message
    triggerFbMessage(true);
    initGlobalStyle(theme);

    window.parent.postMessage(
      {
        type: MessageType.HASH_CHANGE,
        content: {
          pathname: props.location.pathname,
        },
      },
      '*',
    );
  }, []);

  const { animationSelectorClass } = useGlobalAnimation(theme);

  const TopList = isCustom ? ['header'] : ['site-select', 'announcement-bar', 'header'];

  if (!isEdit) {
    // TopList.push('pop-list');
  }
  const currentContentForIndex = get(theme, 'current.content_for_index', []).filter((item) => !TopList.includes(item)); // 过滤脏数据
  let contentForIndex = TopList.concat(currentContentForIndex).concat(['footer']);
  const { decorationActiveMap, changeDecorationActiveFun } = useDecorationActiveMap(contentForIndex);
  useEffect(() => {
    let timer = null;
    if (typeof current !== 'undefined') {
      let sectionEl = document.querySelector(`.section-${current}`);
      if (!sectionEl) {
        timer = setInterval(() => {
          sectionEl = document.querySelector(`.section-${current}`);
          if (sectionEl) {
            timer && clearInterval(timer);
            scrollSectionInView(sectionEl);
            changeDecorationActiveFun(current, false);
          }
        }, 500);
      } else {
        scrollSectionInView(sectionEl);
        changeDecorationActiveFun(current, false);
      }
    }

    return () => {
      timer && clearInterval(timer);
    };
  }, [current]);

  if (theme?.current?.hide_header) {
    contentForIndex = contentForIndex.filter((item) => item !== 'header');
    // theme.current.sections.header.disabled = true;
  }
  if (theme?.current?.hide_footer) {
    contentForIndex = contentForIndex.filter((item) => item !== 'footer');
    // theme.current.sections.footer.disabled = true;
  }

  if (isNotNeedHeader) {
    return <Style>{children}</Style>;
  }
  const sections = get(theme, 'current.sections', {});
  const originSections = get(themeOrigin, 'current.sections', {});
  const { cart_enable_ajax: cartEnableAjax = true } = get(theme, 'current', {});
  let { getValueAfterFormate } = globalConfigOfSectionMap.footer || {};
  const socialLinkArrFooter = isFunction(getValueAfterFormate) ? getValueAfterFormate(theme) : [];

  if (isEdit) {
    let compareNum = isCustom ? 2 : 4;
    if (theme?.current?.hide_header) {
      compareNum -= 1;
    }
    if (theme?.current?.hide_footer) {
      compareNum -= 1;
    }
    if (contentForIndex.length <= compareNum) {
      contentForIndex.splice(contentForIndex.length - 1, 0, 'empty');
    }
  }

  const Empty = () => {
    return (
      <div className='empty'>
        <img className='empty-image' src='https://b.yzcdn.cn/i18n-un-select.png' />
        <div className='empty-title'>
          {typeof window === 'undefined' ? '' : window?.decoration_b_lang?.lang?.['design.design.no_add_com'] || ''}
        </div>
        <div className='empty-desc'>
          {typeof window === 'undefined'
            ? ''
            : window?.decoration_b_lang?.lang?.['design.design.no_add_com_desc'] || ''}
        </div>
      </div>
    );
  };

  return (
    <Style>
      <div className={cn('h5-preview-content', { 'no-a-jump': isCustom })}>
        <>
          {contentForIndex.map((hashId, index) => {
            if (hashId === 'empty') {
              return <Empty key={emptyKey} />;
            }
            let item = sections[hashId];
            if (isCustom && [...TopList, 'footer'].includes(hashId)) {
              item = originSections[hashId];
            }

            const Elem = isEdit ? memoedWrapedSectionsMap[item?.type] : SectionsMap[item?.type]?.component;
            getValueAfterFormate = (globalConfigOfSectionMap[item?.type] || {}).getValueAfterFormate;
            const socialLinkArr = isFunction(getValueAfterFormate) ? getValueAfterFormate(theme) : [];
            let eleProps = {
              ...item,
              theme,
              componentId: hashId,
              locale: props.locale,
              key: hashId,
              domain,
              history,
              initialData,
              elemClass: `section-${hashId} ${animationSelectorClass}`,
              cartEnableAjax,
              socialShareData: getSocialShareData(),
              socialLinkArr,
              socialLinkArrFooter,
            };
            if (isEdit) {
              eleProps = {
                ...eleProps,
                hashId,
                isFirst: index === 0,
                isCustom,
                decorationActive: decorationActiveMap[hashId],
                changeDecorationActiveFun,
              };
            }
            if (!Elem) return null;
            const ElemWithProps = <Elem {...eleProps} />;
            return !item?.disabled && Elem ? ElemWithProps : null;
          })}
          <DistributionCoupon customerId={customerId} currency={currency} />
          <ScrollTop />
          {showBottom ? (
            <>
              {isSimple ? (
                <div onClick={onEditClick} className='preview-edit-btn'>
                  <svg className='icon' aria-hidden='true' width='24' height='24'>
                    <use xlinkHref='#iconic_edit' />
                  </svg>
                </div>
              ) : (
                <div className='preview-bottom-menu'>
                  <div className='preview-menu-left'>
                    <span className='preview-menu-status'>{$t(intl, 'design.cur_preview')}</span>
                    <span className='preview-template-name'>{templateName}</span>
                  </div>
                  <div className='preview-menu-right'>
                    <Button onClick={onPreviewClose}>{$t(intl, 'design.close_preview')}</Button>
                    <Button onClick={onPreviewShare} type='primary'>
                      {$t(intl, 'design.shared_preview')}
                    </Button>

                    <div onClick={onBottomHide} className='preview-close-btn' />
                  </div>
                </div>
              )}
            </>
          ) : null}
        </>
      </div>
    </Style>
  );
};

/**
 * 服务端请求数据
 */
// eslint-disable-next-line @typescript-eslint/no-empty-function
Home.getInitialProps = () => {}; // getInitialProps存在表示需要服务端渲染

export default Home;
